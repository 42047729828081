import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/nulogy.design/nulogy.design/src/components/MarkdownRenderer.js";
import { LoadingAnimation } from "@nulogy/components";
import { config } from "../../playgrounds/loading-animation";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Playground = makeShortcode("Playground");
const PropsTable = makeShortcode("PropsTable");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`LoadingAnimation`}</h1>
    <p>{`Loading animation is used to indicate a delay in the system when duration can not be estimated.`}</p>
    <Playground {...config} mdxType="Playground" />
    <h2>{`Use`}</h2>
    <ul>
      <li parentName="ul">{`When you want to overlay the entire screen with the animation`}</li>
      <li parentName="ul">{`When you don't know how long it will take for the action to be executed`}</li>
      <li parentName="ul">{`When the action will take at least 2 seconds to execute`}</li>
    </ul>
    <h2>{`Props`}</h2>
    <PropsTable propsConfig={config.props} mdxType="PropsTable" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      